import React from "react"
import { Link } from "gatsby"
import useMinimalConfig from "../../hooks/use-minimal-config"
import useSiteMetadata from "../../hooks/use-site-metadata"
import replaceSlashes from "../../../utils/replace-slashes"
import Icon from "../icons"
import { FooterType } from "../../types"

type LayoutProps = {
  footer: FooterType
  [key: string]: any
}

const FooterUHSM = ({ footer, ...props }: LayoutProps) => {
  const { socialMediaLinks, footerNavigation: nav, basePath } = useMinimalConfig()
  const { siteImage, siteTitle, footerCopyrightText } = useSiteMetadata()

  return (
    <footer className={`bg-footerBackground text-footerColor ${footer.advanced?.class || ``}`}>
      <div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-0 py-16 md:py-12 overflow-hidden">
        <img src={siteImage} className="mx-auto md:w-min md:pl-5 md:pr-10 md:mr-10 md:-mb-5 md:float-left" />
        <nav className="mt-10 md:ml-10 md:pl-10" aria-label="Footer">
          {nav.map((item: any, itemIndex: any) => (
            <div key={`footer-${itemIndex}`} className="text-center md:text-left mx-10 md:pl-10">
              <a href={item.slug} className="text-footerColor" target="_blank">{item.title}</a>
            </div>
          ))}
        </nav>
        <div className="flex justify-center gap-6 mt-10 md:-mt-12 md:float-right">
          {socialMediaLinks.map((item: any, index: any) => (
            <div key={`social-${index}`}>
              <a href={item.url} target="_blank">
                <Icon icon={item.icon} size={20} />
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className="container pb-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <p className="px-5 text-white">{footerCopyrightText}</p>
      </div>
    </footer>
  )
}

export default FooterUHSM
